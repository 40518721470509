@font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(./fonts/Saudagar.ttf) format('truetype');
}
html{margin: 0px !important;
padding: 0px !important;}
/* *{border:1px solid red} */
body {
  margin: 0;
  overflow-x:hidden ;
  background-color: #ffffff !important;
  font-family: 'MyFont', sans-serif !important;

}
/******* Font Styles*******/
.font-400{
  font-weight: 400 !important;
}
.font-500{
  font-weight: 500 !important;
}
/*********************widths***********************/
.w-lg-25{
  width: 25% !important;
}
@media(max-width:768px){
  .w-sm-50{
    width: 50% !important;
  }
  .w-sm-75{
    width: 75% !important;
  }
}

/*******************Colors*********************/
.text-bej{
  color: #9C702A;
}
.text-babyblue{
  color:#34C4EC !important;
}
.text-babyblue:hover{
  color:#34C4EC !important;
}
.text-primary-dark{
  color: #353330;
}
.text-primary-dark:hover{
  color: #353330;
}
.text-secondary-dark{
  color: #8C8C8C;
}
.text-pink{
  color: #E9A1B7;
}
.bg-pink{
  background-color:#E9A1B7 ;

}
/********************************Font-sizes****************************/
.font-size-20{
  font-size: 20px;
}

/************************buttons***************************/
.btn-primary-dark{
  background-color:#353330 !important;
  color: white !important;
  padding: 3px 5px !important;
  border: none !important;
  border-radius: 5px !important;
}
.btn-primary-dark:hover{
  color: white !important;
  background-color:#353330 !important;

}
.btn-primary-dark:disabled,
.btn-primary-dark[disabled]{
  background-color:#808080 !important;
  color: white !important;
  
}

.btn-secondary-dark{
  background-color:#8C8C8C ;
  color: white !important;
  border-radius: 3px;
  padding: 2px 5px;
  border:none

}

.btn-secondary-dark:disabled,
.btn-secondary-dark[disabled]{
  background-color:#acacac ;
  color: white !important;
 

}
.btn-pink:hover{
  color: white !important;

}
.curser-pointer{
  cursor: pointer;
}

/******************Media Queries*****************/

@media(min-width:992px){

.hide-for-desktop{
  display: none !important;
}
}
@media(max-width:991px){
  .hide-for-mobile{
    display: none !important;
  }

}
/***************Navbar ****************/
/* .my-navbar{
  background: rgba(255, 255, 255, 1); 
  backdrop-filter: blur(8px); 
  z-index: 2;
} */
.langbtn{
  cursor: pointer;
}
/* .logo-div-navbar{
width: 12%;
position: absolute;
top: 1%;
left: 5%;
z-index: 6;

}
.logo-div-navbar-ar{
  width: 12%;
  position: absolute;
  top: 1%;
  right: 5%;
  z-index: 6;

  } */
  .buttons-div-navbar{
 position: absolute;
 top: 2vh;
 right: 1vh;

  }
  @media(max-width:768px){

    .logo-div-navbar{
      width: 20%;
      position: absolute;
      top: 1%;
      left: 5%;
      z-index: 6;
      
      }
      .logo-div-navbar-ar{
        width: 20%;
        position: absolute;
        top: 1%;
        right: 5%;
        z-index: 6;
      
        }


  }
/*************branches*******/
.branches-div{
  height: 100vh;
}
.branches-card{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: linear-gradient(to right,rgba(255, 143, 143, 0.2), rgba(141, 208, 253, 0.2)); 
  backdrop-filter: blur(8px); 
  z-index: 2;
  width: 30vw;
  border-radius: 10px;
}
/*********************Registration Page*****/
.registration-section{
  min-height: 60vh;
  /* background: linear-gradient(to right, rgba(141, 208, 253, 0.2),rgba(255, 143, 143, 0.2)); 
  backdrop-filter: blur(8px);  */
}
.back-span{
  text-decoration: underline;
  cursor: pointer;

}
/***************Home**************/
.pb-bg{
  background: linear-gradient(to right,rgba(75, 194, 235, .5), rgba(232, 42, 116,.5)); 

}
.home-1st-section{
font-family:  'Marhey', cursive;
}
.logo-div{
  width: 50%;

}
.header-text-div{
  width: 50%;
}
.ads-slider-img{
  width: 100%;
height: 70vh;  
object-fit: cover;
}
@media(max-width:768px){

  .logo-div{
    width: 100%;
    }
    .header-text-div{
      width: 100%;
    }

}
/*****************SubCategories******************/
.subcategory-card{
cursor: pointer;
}
.subcategory-card:hover  .subcategory-card-img-div{
  filter:brightness(60%);
}
.subcategory-card-img-div{
  width: 100%;
  overflow: hidden;
}
.subcategory-card-img{
  width: 100%;
  height: 50vh;

  object-fit: cover;
  border-radius: 20px;
}
/*****************Products*******************/

.product-img{
border-radius: 20px;
  height:50vh ;
  object-fit: cover;
}
.product-mini-img{
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 5px;
  max-height: 10vh;
  max-width: 6vw;
  cursor: pointer;
}
.product-mini-img:hover{
  filter: brightness(80%);
}
@media(max-width:687px){
  .product-img{
    border-radius: 10px;
      height:20vh ;
      object-fit: cover;
    }
.product-add-to-cart-text{
  display: none;
}
}

.search-bar{
  border: 3px solid #34C4EC !important;
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  background: url(/src/imgs/search.png) no-repeat scroll 4% 50%;
  background-size: 5%;
  padding-left:30px;
  }
  .search-bar:focus{
      /* border: 1px solid #3B3B3B; */
      outline: none !important;
  
  }
  .search-btn{
background-color: #34C4EC;
color: white;
border: none;
padding: 0px 20px;
  }
  .ob{
    opacity: .2;
  }
  

  /***********************Footer***********************/

  .footer-div{
    min-height: 30vh;
  }

  /*********************Cart***************************/
  .cart-main-div{

min-height:70vh ;

  }
.cart-item{
  height: 30vh;

}
.cart-item-img{
  height: 20vh;
}
  /*********************Delivery page***************************/
  .delivery-details-section{
    min-height: 65vh;
  }

  .delivery-details-div{
  background-color: #8C8C8C;
  border-radius: 10px;
}

.map-container{
 
width: 100%;
height: 20vh;


}
.reciept-div{
  background-color:#8C8C8C;
  border-radius: 10px;
  width: 50%;
}

.disabled-link {
  pointer-events: none;
}

.my-orders-div{
  min-height: 70vh;
}

.product-image-cart{
  height: 40vh;
  object-fit: cover;
  border-radius: 20px;
}
.payment-imgs{
  height: 40vh;
  
}


.order-card{
  border: #353330 solid 2px;
}
@media(max-width:768px){
  
  .reciept-div{
    border-radius: 10px;
    width: 90%;
  }
  .payment-imgs{
    height: 20vh;
    margin: auto;
    display: block;
    
  }
}